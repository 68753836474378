<template>
  <div class="twoLevel">
    <div class="two_level_container">
      <router-view></router-view>
    </div>
    <div class="jiao">
      <div class="tu">
        <a target="_blank" href="https://www.tiktok.com/@haimamedia_official"><img src="../assets/image/tk.png" /></a>
        <a target="_blank" href="https://www.youtube.com/channel/UCdYjLQIFkJcNDbtO-yY6ftA"><img
            src="../assets/image/YouTube.png" /></a>
      </div>
      <p>©COPYRIGHT 2019-2023ALL RIGHTS RESERVEDKOLBOX SOCIAL</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .twoLevel {
    margin-top: 100px;

    .two_level_container {
      height: calc(100vh - 150px);
      width: 1200px;
      background: #fff;
      padding-top: 1px;
      margin: 0 auto;
    }

    .two_level_bottom {
      width: 100%;
      height: 201px;
      background: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
    }

    .jiao {
      .tu {
        margin-left: 20px;
        position: absolute;
        bottom: 10px;

        a:nth-child(2) {
          margin-left: 10px;
        }
      }

      p {
        position: absolute;
        right: 50px;
        color: #000;
        bottom: 17px;
      }
    }
  }

</style>
